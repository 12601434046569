import { createApp } from "vue";
import App from "./App.vue";
import { bootstrap } from "./cool";
import "./cool/utils/number";
import createI18n from "./modules/i18n";
import getLanguage from "./utils/lang";
import I18NextVue from "i18next-vue";

import { getOem } from "/@//utils/oem";
import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import { registerTransferValue } from "./cool/utils/ext_string";
import { getOemSetting } from "/@/utils/oem";
import { ElMessageBox } from "element-plus";
dayjs.extend(utc);
dayjs.extend(timezone);

const app = createApp(App);
// 启动
bootstrap(app)
	.then(async () => {
		// 加载数据
		const langExtraResp: any = await getLanguage();
		//获取OEM 对应的配置
		await getOem();
		//插入Link icon
		const favicon = getOemSetting("favicon");
		if (favicon) {
			const link = document.createElement("link");
			link.rel = "icon";
			link.href = favicon;
			document.head.appendChild(link);
		}
		const i18n = createI18n(langExtraResp);
		registerTransferValue(app);
		app.use(I18NextVue, { i18next: i18n }).mount("#app");
	})
	.catch((err) => {
		ElMessageBox.alert(err.message);
		console.error("平台 启动失败", err);
	});
