import { defineStore } from "pinia";
import { ref } from "vue";
import { storage } from "/@/cool/utils";
import * as Dict from "/$/takephoto/dict/takephoto";

interface Shop {
	[key: string]: any;
}

interface Equipment {
	[key: string]: any;
}

interface CameraConfig {
	[key: string]: any;
}

interface Options {
	[key: string]: any;
}

interface Count {
	[key: string]: any;
}
interface TakePhotoStatus {
	[key: string]: any;
}
interface PlayAudio {
	[key: string]: Function;
}

interface ActionPos {
	[key: string]: any;
}

interface TakeData {
	[key: string]: any;
	shopId: Number;
	equipmentId: Number;
	customerId: Number;
	uuid: String;
	qrcodeUrl: String;
	shopInfo: Shop;
	eqInfo: Equipment;
	enableDigital: Boolean;
	// deviceSerial: String;
	// validateCode: String;
	cameraConfig: CameraConfig;
	options: Options;
	playHuaWeiFlag: Boolean;
	loadDataStatus: Number;
	count: Count;
	takephotoStatus: TakePhotoStatus;
	playAudio: PlayAudio;
	actionPos: ActionPos;
}

const Pending = 0;
const Loading = 1;
const Loaded = 2;

// 本地缓存
const data = storage.info();

export const useTakeStore = defineStore("take", function () {
	// 数据信息
	const takeData = ref<TakeData>(data.takeData || {});

	function setEquipmentId(value: Number) {
		takeData.value.equipmentId = value;
		takeData.value.loadListData = Pending;
		storage.set("takeData", takeData.value);
	}

	function setCustomerId(value: Number) {
		takeData.value.customerId = value;
		storage.set("takeData", takeData.value);
	}

	function setUuid(value: String) {
		takeData.value.uuid = value;
		storage.set("takeData", takeData.value);
	}

	function setShopId(value: Number) {
		takeData.value.shopId = value;
		storage.set("takeData", takeData.value);
	}

	function setQRCodeUrl(value: String) {
		takeData.value.qrcodeUrl = value;
		storage.set("takeData", takeData.value);
	}

	function setShop(value: Shop) {
		takeData.value.shopInfo = value;
		storage.set("takeData", takeData.value);
		if (value.id) {
			setShopId(value.id);
			setEnableDigital(value.use_digital == 1);
		} else {
			setShopId(0);
			setQRCodeUrl("");
			setEnableDigital(false);
		}
	}

	function setEq(value: Equipment) {
		takeData.value.eqInfo = value;
		storage.set("takeData", takeData.value);
		setVideoParam(value);
	}

	function setOptions(value: Options) {
		takeData.value.options = value;
		storage.set("takeData", takeData.value);
	}

	function setEnableDigital(value: Boolean) {
		takeData.value.enableDigital = value;
		storage.set("takeData", takeData.value);
	}

	function setDeviceSerial(value: String) {
		takeData.value.deviceSerial = value;
		storage.set("takeData", takeData.value);
	}

	function setValidateCode(value: String) {
		takeData.value.validateCode = value;
		storage.set("takeData", takeData.value);
	}

	function setCameraConfig(value: CameraConfig) {
		takeData.value.cameraConfig = value;
		storage.set("takeData", takeData.value);
	}

	function setCount(value: Count) {
		takeData.value.count = value;
		takeData.value.disabledTake = !!value.disabledTake;
		storage.set("takeData", takeData.value);
	}

	function setVideoParam(eq: Equipment) {
		if (!eq.id) {
			setEquipmentId(0);
			setUuid("");
		} else {
			setEquipmentId(eq.id);
			setUuid(eq.uuid);
		}

		if (eq.ext_config && eq.ext_config.hik_enable_status == 1) {
			setDeviceSerial(eq.ext_config.hik_serial_no);
			setValidateCode(eq.ext_config.hik_validate_code);
			setCameraConfig(eq.ext_config);
		} else {
			setDeviceSerial("");
			setValidateCode("");
			setCameraConfig({});
		}
	}

	function setUpdateCustomerFn(fn: Function) {
		takeData.value.updateCustomerFn = fn;
		storage.set("takeData", takeData.value);
	}

	function setPlayHuaWeiFlag(value: Boolean) {
		takeData.value.options.playHuaWeiFlag = value;
		storage.set("takeData", takeData.value);
	}

	function setLoadDataPending() {
		takeData.value.loadDataStatus = Pending;
		storage.set("takeData", takeData.value);
	}

	function setLoadDataLoaded() {
		takeData.value.loadDataStatus = Loaded;
		storage.set("takeData", takeData.value);
	}

	function setPlayVideo(fn: Function) {
		takeData.value.playVideoFn = fn;
		storage.set("takeData", takeData.value);
	}

	function setStopPlayVideo(fn: Function) {
		takeData.value.stopPlayVideoFn = fn;
		storage.set("takeData", takeData.value);
	}

	// 设置拍照状态
	function setTakePhotoStatus(value: TakePhotoStatus) {
		takeData.value.takephotoStatus = value;
		storage.set("takeData", takeData.value);
	}

	function setPlayAudio(value: PlayAudio) {
		takeData.value.playAudio = value;
		storage.set("takeData", takeData.value);
	}

	function setDisabledTake(value: boolean) {
		takeData.value.disabledTake = value;
		storage.set("takeData", takeData.value);
	}

	function setActionPos(value: ActionPos) {
		takeData.value.actionPos = value;
		storage.set("takeData", takeData.value);
	}

	// 同步数据
	function resetSyncData() {
		const countData = count();
		countData.sync = {
			count: 0,
			delay_synced: 0,
			synced: 0,
			syncing: 0
		};
		setCount(countData);
	}

	function reset() {
		setEquipmentId(0);
		setCustomerId(0);
		setUuid("");
		setShopId(0);
		setQRCodeUrl("");
		setShop({});
		setEq({});
		setEnableDigital(false);
		setOptions({});
		setCount({});
	}

	function shop() {
		return takeData.value.shopInfo;
	}

	function eq() {
		return takeData.value.eqInfo;
	}

	function uuid() {
		return takeData.value.uuid;
	}

	function shopId() {
		return takeData.value.shopId;
	}

	function customerId() {
		return takeData.value.customerId;
	}

	function equipmentId() {
		return takeData.value.equipmentId;
	}

	function qrcodeUrl() {
		return takeData.value.qrcodeUrl;
	}

	function enableDigital() {
		return takeData.value.enableDigital;
	}

	function deviceSerial() {
		return takeData.value.deviceSerial;
	}

	function validateCode() {
		return takeData.value.validateCode;
	}

	function cameraConfig() {
		return takeData.value.cameraConfig;
	}

	function options() {
		return takeData.value.options;
	}

	function isLocalCameraStream() {
		return takeData.value.cameraConfig?.video_stream_type == "local_video_stream";
	}

	function playHuaWeiFlag() {
		return takeData.value.options?.playHuaWeiFlag;
	}

	function callUpdateCustomerFn() {
		takeData.value.updateCustomerFn && takeData.value.updateCustomerFn();
	}

	function count() {
		return takeData.value.count;
	}

	// 同步超时
	function isOverSyncTime() {
		return count().sync?.delay_synced > 0;
	}

	// 同步完成
	function isSyncTime() {
		if (count().sync?.delay_synced > 0) {
			return false;
		}

		if (count().sync?.synced == 0) {
			return false;
		}

		return count().sync?.count == count().sync?.synced;
	}

	function queue() {
		return count()?.queue || [];
	}

	function isDelayUploadModel() {
		return !!count()?.isDelayMode;
	}

	function loadDataStatus() {
		return takeData.value.loadDataStatus;
	}

	function playVideo() {
		return takeData.value.playVideoFn && takeData.value.playVideoFn();
	}

	function stopPlayVideo() {
		return takeData.value.stopPlayVideoFn && takeData.value.stopPlayVideoFn();
	}

	function takephotoStatus() {
		return takeData.value.takephotoStatus;
	}

	function playAudio(key: string) {
		takeData.value.playAudio[key] && takeData.value.playAudio[key]();
	}

	function disabledTake() {
		return takeData.value.disabledTake;
	}

	function actionPos() {
		return takeData.value.actionPos;
	}

	return {
		takeData,
		setEquipmentId,
		setCustomerId,
		setUuid,
		setShopId,
		setQRCodeUrl,
		reset,
		setShop,
		setEq,
		setEnableDigital,
		setDeviceSerial,
		setValidateCode,
		setCameraConfig,
		setOptions,
		setPlayHuaWeiFlag,
		setUpdateCustomerFn,
		setCount,
		setLoadDataLoaded,
		resetSyncData,
		setPlayVideo,
		setStopPlayVideo,
		setTakePhotoStatus,
		setPlayAudio,
		setDisabledTake,
		setActionPos,
		shop,
		eq,
		uuid,
		shopId,
		customerId,
		equipmentId,
		qrcodeUrl,
		enableDigital,
		deviceSerial,
		validateCode,
		cameraConfig,
		options,
		isLocalCameraStream,
		playHuaWeiFlag,
		callUpdateCustomerFn,
		count,
		queue,
		isDelayUploadModel,
		loadDataStatus,
		isOverSyncTime,
		isSyncTime,
		playVideo,
		stopPlayVideo,
		takephotoStatus,
		playAudio,
		disabledTake,
		actionPos
	};
});
