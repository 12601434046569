import { useAppStore } from "./app";
import { useMenuStore } from "./menu";
import { useProcessStore } from "./process";
import { useUserStore } from "./user";
import { useShopStore } from "./shop";
import { useTakephotoStore } from "./takephoto";
import { useWatermaskStore } from "./watermask";
import { useLangStore } from "./lang";
import { useTimezoneStore } from "./timezone";
import { useTakeStore } from "./take";
import { useOemStore } from "./oem";

export function useBaseStore() {
	const app = useAppStore();
	const menu = useMenuStore();
	const process = useProcessStore();
	const user = useUserStore();
	const takephoto = useTakephotoStore();
	const shop = useShopStore();
	const watermask = useWatermaskStore();
	const lang = useLangStore();
	const timezone = useTimezoneStore();
	const takeData = useTakeStore();
	const oem = useOemStore();

	return {
		app,
		menu,
		process,
		takephoto,
		shop,
		user,
		watermask,
		lang,
		timezone,
		takeData,
		oem
	};
}

const useStore = useBaseStore;

export { useStore };
