import { useCool, config } from "/@/cool";

import { useBase } from "/$/base";

export const getOem = async () => {
	const { oem } = useBase();
	const service = useCool();
	//获取当前的域名
	const domain = window.location.host;
	const data = await service.service.oem.info({
		domain: domain,
		baseUrl: config.baseUrl,
		forceBaseUrl: true
	});
	oem.set(data);
};

export const getOemSetting = (key: string) => {
	return useBase().oem.getValue(key);
};
