import i18n from "i18next";
import zh from "./lang/zh";
import zhTW from "./lang/zh-TW";
import zhHK from "./lang/zh-HK";
import en from "./lang/en";
import { merge } from "lodash";

import LanguageDetector from "i18next-browser-languagedetector";

const create = (data: any) => {
	const messages = {
		"zh-CN": zh,
		"zh-TW": zhTW,
		"zh-HK": zhHK,
		en
	};

	Object.keys(messages).forEach((key: string) => {
		merge(messages[key], data[key]);
	});

	// for (const key in data) {
	// 	merge(messages[key], data[key]);
	// }
	// const i18n = createI18n({
	// 	locale: localStorage.getItem("locale") || navigator.language.slice(0, 2),
	// 	messages: messages
	// });

	i18n.use(LanguageDetector).init({
		resources: {
			en: { translation: messages["en"] },
			"zh-CN": { translation: messages["zh-CN"] },
			"zh-HK": { translation: messages["zh-TW"] }, // TODO: 暫時採用台灣版本的繁體中文
			"zh-TW": { translation: messages["zh-TW"] }
		},
		fallbackLng: "zh-CN",
		debug: true,
		react: {
			useSuspense: false
		},
		detection: {
			order: ["querystring", "localStorage", "navigator"],
			lookupQuerystring: "lang"
		}
	});

	return i18n;
};

export default create;
